import React from 'react';
import { Violator } from '@hpstellar/core';

import { Helpers } from '../../core/src/helpers';
import { addGtmPropsToProductTile } from '../../metrics/metrics-helpers';
import DealViolator from '../components/deals/deal-violator';
import {
    getImageLink,
    mapBundleProducts,
    getRewardsBadge,
    getCompare,
    getTileCtaLink,
    getProductLinkProps,
    resolveViolatorMessage,
    getRatings,
    getOffersModals,
} from './util';
import { getPunchOutProps, getPurchaseButtonProps, getStockIndicator, getPriceProps } from './cart';

export const getSaleCalloutText = (saleCallout, price) => {
    const { priceDifference } = price || {};
    let promoText = saleCallout;
    if (saleCallout === '%') {
        promoText = priceDifference > 0 ? `{%}` : '';
    } else if (saleCallout === '$') {
        promoText = priceDifference > 0 ? `{$}` : '';
    }
    return Helpers.setDynamicProductValues(promoText, price);
};

const getBadge = product => {
    const { prdClass } = product;
    return prdClass === 'CTO'
        ? 'Customizable'
        : prdClass === 'FIXEDBUNDLE'
          ? 'Bundle'
          : ['VGC'].includes(prdClass) || Helpers.isECarepack(product)
            ? 'Digital'
            : null;
};

export const getImages = (product, options = {}, colorSelection) => {
    const { images: defaultImages } = product || {};
    const { colors, activeIndexes } = colorSelection || {};
    const [index] = activeIndexes || [];
    let images = defaultImages;
    try {
        const activeColor = (colors || [])[index];
        //if a different color is selected try to find the matching color
        if (activeColor) {
            const altImages = defaultImages.reduce((r, i) => {
                try {
                    const matchingImages = (i.altImages || []).find(
                        i => i.color.toLowerCase().trim() === activeColor.sku.toLowerCase().trim(),
                    );
                    if (matchingImages) {
                        r.push(matchingImages);
                    }
                } catch (e) {}
                return r;
            }, []);
            images = altImages.length > 0 ? altImages : images;
        }
    } catch (e) {}
    //peak the first image, if the format is not correct map it
    const [firstImage] = images || [];
    return !options.resizeImage && (!firstImage || firstImage.src)
        ? images
        : (images || []).reduce((r, image, idx) => {
              //don't include videos on product tiles
              if (image && image.type !== 'video') {
                  r.push({
                      alt: product.title || product.name,
                      src: getImageLink(image.src || image.image || image.lrg, options.resizeImage),
                  });
              }
              return r;
          }, []);
};

const filterOffers = (pStoreID = 'gs') =>
    Helpers.memoize(
        (offers, sku) => {
            return offers?.filter(offer => {
                const { value, privateStoreOnly } = offer || {};
                return (
                    value &&
                    value.toLowerCase() !== 'save $0 instantly' &&
                    (!privateStoreOnly || privateStoreOnly.includes(pStoreID))
                );
            });
        },
        (offers, sku) => `${offers?.length}-${sku}`,
    );

/**
 * Derives commonly use props from product and price information from StoreApp api product results
 * @param {*} product
 * @param {*} price
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */
const getStoreAppProductProps = (
    product,
    priceObj,
    hooks,
    options = { resizeImage: false },
    analyticsData,
    trackCustomMetric,
) => {
    const { ctaViewDetailsLink, sku, linkUrl: productUrl, attributes, metadata } = product;
    const { compare = {}, colorSelection } = hooks || {};
    const { urlParams = {}, translations } = options;
    const { facet_segment, energyeffcomp } = attributes || {};
    const { violator, saleCallout, intraDayMessage } = metadata || {};

    let gtmActions;
    if (analyticsData) {
        let { list, gtmId, position, prices } = analyticsData;
        gtmActions = addGtmPropsToProductTile(
            {
                ...product,
                customGtmId: `${list}-${sku}`,
                sku,
                price: prices || priceObj,
                position,
            },
            analyticsData,
            { [sku]: priceObj },
        );
    }

    const { priceDifference, pStoreID, deal, jTierDis } = priceObj || {};
    const { activeDeal } = deal || {};
    const ctaLink = productUrl || ctaViewDetailsLink;
    const ratings = getRatings(product);
    let promoText = getSaleCalloutText(saleCallout, priceObj);

    const rewardBadge = getRewardsBadge(product, pStoreID);
    const images = getImages(product, options, colorSelection);

    const { buttons = {}, ...restTranslations } = translations || {};
    const productTranslations = {
        buttons: {
            compareBtnText: 'ADD TO COMPARE',
            ...buttons,
        },
        ...restTranslations,
    };

    const usageLabel = [];
    if (facet_segment) usageLabel.unshift(facet_segment);
    if (product.rank && product.rank.newest === 0) usageLabel.unshift('NEW');
    if (energyeffcomp && energyeffcomp.toLowerCase().indexOf('energy star') > -1) usageLabel.unshift('ENERGY STAR');

    const badge = getBadge(product);

    const violatorMessage = violator || product.violatorMsg;

    const offerMessage = activeDeal ? (
        <Violator variation="primary">
            <DealViolator activeDeal={activeDeal} price={priceObj} message={intraDayMessage} className="secondary" />
        </Violator>
    ) : (
        promoText
    );
    const transformedProduct = {
        ...product,
        // TODO: need for compare cta to work, its using purchaseButton component
        prdClass: product.product_class || product.product_type || product.prdClass,
        product_type: product.product_class || product.product_type || product.prdClass,
        name: product.title || product.name,
        title: product.title || product.name,
        productUrl: getTileCtaLink(ctaLink, urlParams),
        // api inconsistency CTO badge comes in as productBadge in VWA and productType in MDP
        productType: product.productBadge || product.productType || badge,
        violatorMessage: resolveViolatorMessage(violatorMessage),
        specFirstGlance: product.ksps,
        usageLabel,
        images,
        //keep offer message in product data so that it can be used in the compare view
        offerMessage,
    };
    const tierDiscount = jTierDis ? `Tier Discount $${jTierDis}` : null;

    const bundleComponents = product.bundleComponents || (attributes && attributes.bundleComponents);
    const productTileProps = {
        headerProps: {
            offerMessage,
            ...getStockIndicator(priceObj),
        },
        product: transformedProduct,
        ...getPunchOutProps(priceObj),
        ratings,
        priceProps: getPriceProps(product, priceObj, options),
        translations: productTranslations,
        rewardBadge,
        bundleModule: mapBundleProducts(bundleComponents, priceObj, true),
        ...getCompare(product, compare),
        offerTypeSLP: activeDeal ? 'primary' : 'secondary',
        ...getPurchaseButtonProps(product, priceObj, hooks, analyticsData, trackCustomMetric),
        //pass transformed product to get ctaLink with jumpid for slp
        ...getProductLinkProps(transformedProduct, priceObj, analyticsData),
        colorSelection,
        ...getOffersModals(product, compare, priceObj),
    };

    return productTileProps;
};

export default getStoreAppProductProps;
