import React from 'react';

import { getDerivedProductMetricsV2, addGtmProductClick, addGtmAddToCart } from '../../metrics/metrics-helpers';
import { getImageLink, resolveOfferMsg, getTileCtaLink } from './util';
import {
    handleUpdateCart,
    getButtonIcon,
    getPunchOutProps,
    getCTAText,
    handleAddToCart,
    getPurchaseButtonProps,
} from './cart';
import { Helpers } from '../../core/src/helpers';

/**
 * Derives commonly use props for attachable product and price information
 * @param {*} product
 * @param {*} price
 * @param {Function} onAttach - Optional callback function for when the product is added
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */
const getAttachableProductProps = (
    product,
    priceObj,
    onAttach,
    hooks,
    options,
    analyticsData,
    trackCustomMetric,
    showOOS = false,
) => {
    const { product_type, prdClass, sku, linkUrl, name, images: productImages = [] } = product;
    //NOTE: ignore product ctaViewDetails always use "Learn More" ticket HPEDT-9825
    const ctaViewDetails = 'Learn More';
    const {
        resizeImage = false,
        translations,
        detachable,
        attached,
        showOfferMessage,
        linkTarget,
        eligibleSKU,
        removeExistingAssociation,
        priceProps,
    } = options || {};
    const images = productImages.map(img => ({
        ...img,
        url: getImageLink(img.image || img.src || img.lrg, resizeImage),
        alt: img.alt || img.altText || name,
    }));
    const { addToCart } = hooks || {};
    const productType = product_type || prdClass || (priceObj && priceObj.productType);

    const {
        regularPrice,
        salePrice,
        tierMsg,
        locale,
        currency,
        priceDifference,
        ctoLink: customizeUrl,
        contingentOfferMessage,
        isOOS,
        hideAddToCart,
        salePriceSuffix,
        customSaleText,
    } = priceObj || {};
    const price = salePrice
        ? {
              ...(priceProps || {}),
              customSaleText,
              regularPrice,
              salePrice,
              productType,
              locale,
              currency,
              tierMsg,
              priceDifference,
              hideAddToCart,
              translations: { salePriceSuffix },
          }
        : {};
    const link = {
        to: getTileCtaLink(product.ctaViewDetailsLink || linkUrl), // ctaViewDetailsLink seems to only be available on dev
        text: ctaViewDetails,
        children: ctaViewDetails,
        target: linkTarget,
    };

    const [image = {}] = images;
    const { buttons = {}, ...restTranslations } = translations || {};
    const productTranslations = {
        buttons: {
            attach: getCTAText(product, priceObj),
            detach: 'IN CART',
            showMore: 'Show More',
            showLess: 'Show Less',
            ...buttons,
        },
        ...restTranslations,
    };
    const derivedProductMetrics = getDerivedProductMetricsV2(product, { [sku]: priceObj }, analyticsData) || {};

    const isCto = /cto/i.test(productType || '');
    const skipCTA = isOOS && !showOOS;

    const attachHandler = isOOS
        ? () => {
              // throw exception for OOS to prevent success timeout from triggering in RecommendedAccessories component
              throw 'Product is OOS';
          }
        : !addToCart || skipCTA || hideAddToCart
          ? undefined
          : detachable && attached
            ? handleUpdateCart(product, 0, addToCart, onAttach)
            : handleAddToCart(
                  {
                      ...product,
                      listPrice: priceObj && (priceObj.salePrice || priceObj.regularPrice),
                      ...derivedProductMetrics,
                      metricParams: analyticsData,
                      eligibleSKU,
                      removeExistingAssociation,
                  },
                  addToCart,
                  priceObj,
                  onAttach,
                  undefined,
                  {
                      ...(analyticsData || {}),
                      baseProductSku: sku, // used only for a2c on xsell popup
                  },
                  trackCustomMetric,
              );

    const productUrl = product.productUrl || link.url;
    const { xsellProdInfo, xsellMethod, list, position, cartId, gtmIdPrefix } = analyticsData || {};
    const gtmConfig = {
        list: list || 'pdp-accessories',
        metricKey: 'ctaClick',
        xsellProdInfo,
        xsellMethod,
        position,
        gtmIdPrefix,
    };
    const gtmPrice = { [sku]: priceObj };

    const { gtmActions: gtmProductClick } = addGtmProductClick(product, gtmConfig, gtmPrice);

    let gtmAddToCart = !isCto && (!detachable || !attached) && addGtmAddToCart(product, gtmConfig, gtmPrice, cartId);
    if (!isCto && detachable && attached) {
        gtmAddToCart = addGtmAddToCart(product, { ...(gtmConfig || {}), removeFromCart: true }, gtmPrice, cartId);
    }

    const gtmActions = new Map([
        ['productViewDetails', gtmProductClick.get('productClick')],
        [
            'productCtaAction',
            isCto ? gtmProductClick.get('productClick') : gtmAddToCart && gtmAddToCart.get('ctaClick'),
        ],
    ]);
    const description = typeof product.prdOverview === 'object' ? product.prdOverview?.[0] : product.prdOverview;
    const { purchaseBtnProps } = getPurchaseButtonProps(
        product,
        priceObj,
        hooks,
        //handle analytics at the root level of props rather than the purchase button
        null,
        trackCustomMetric,
        { attached: detachable && attached },
    );
    //remove children from button props so that detach and attach CTA is handled by Component + translations prop
    // unless hideAddToCart is true, then children prop is needed to hide the CTA
    const { children, ...restButtonProps } = purchaseBtnProps || {};
    const buttonProps = hideAddToCart ? purchaseBtnProps : restButtonProps;
    const productTileProps = {
        ...product,
        brand: derivedProductMetrics && derivedProductMetrics.brand,
        category: derivedProductMetrics && derivedProductMetrics.category,
        image,
        link,
        /**The RecommendedAccessories component doesn't quite match the AttachableProductTile props */
        productUrl,
        url: productUrl,
        offerMessage: resolveOfferMsg(
            contingentOfferMessage || product.violatorMessage || product.violatorMsg,
            priceObj,
        ),
        showOfferMessage,
        //only use the first line of text from prdOverview
        description: <span dangerouslySetInnerHTML={Helpers.createMarkup(description)} />,
        translations: productTranslations,
        attachTimeout: isOOS ? null : 1000,
        price,
        ...getPunchOutProps(priceObj),
        attached,
        buttonProps,
        gtmActions,
    };
    if (!hideAddToCart && productTileProps.buttonProps) {
        //override onClick with attachHandler
        productTileProps.buttonProps.onClick = attachHandler;
    }

    return productTileProps;
};

/**
 * Derives commonly use props for attachable product and price information
 * @param {*} product
 * @param {*} price
 * @param {Function} onAttach - Optional callback function for when the product is added
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */
export const getCrossSellProductProps = (
    product,
    priceObj,
    onAttach,
    hooks,
    options,
    analyticsData,
    trackCustomMetric,
    showOOS = false,
) => {
    const { product_type, prdClass, sku, linkUrl, name, images: productImages = [] } = product;
    //NOTE: ignore product ctaViewDetails always use "Learn More" ticket HPEDT-9825
    const ctaViewDetails = 'Learn More';
    const {
        resizeImage = false,
        translations,
        detachable,
        attached,
        linkTarget,
        eligibleSKU,
        removeExistingAssociation,
        priceProps,
    } = options || {};
    const images = productImages.map(img => ({
        ...img,
        url: getImageLink(img.image || img.src || img.lrg, resizeImage),
        alt: img.alt || img.altText || name,
    }));
    const { addToCart } = hooks || {};
    const productType = product_type || prdClass || (priceObj && priceObj.productType);

    const {
        regularPrice,
        salePrice,
        tierMsg,
        locale,
        currency,
        priceDifference,
        isOOS,
        hideAddToCart,
        salePriceSuffix,
        customSaleText,
        subscriptionTerm,
    } = priceObj || {};
    const price = salePrice
        ? {
              ...(priceProps || {}),
              customSaleText,
              regularPrice,
              salePrice,
              productType,
              locale,
              currency,
              tierMsg,
              priceDifference,
              hideAddToCart,
              translations: { salePriceSuffix },
          }
        : {};
    const link = {
        to: getTileCtaLink(product.ctaViewDetailsLink || linkUrl), // ctaViewDetailsLink seems to only be available on dev
        text: ctaViewDetails,
        children: ctaViewDetails,
        target: linkTarget,
    };

    const [image = {}] = images;
    const { buttons = {}, ...restTranslations } = translations || {};
    const productTranslations = {
        buttons: {
            attach: getCTAText(product, priceObj),
            detach: 'IN CART',
            showMore: 'Show More',
            showLess: 'Show Less',
            ...buttons,
        },
        ...restTranslations,
    };
    const derivedProductMetrics = getDerivedProductMetricsV2(product, { [sku]: priceObj }, analyticsData) || {};

    const isCto = /cto/i.test(productType || '');
    const skipCTA = isOOS && !showOOS;

    const attachHandler = isOOS
        ? () => {
              // throw exception for OOS to prevent success timeout from triggering in RecommendedAccessories component
              throw 'Product is OOS';
          }
        : !addToCart || skipCTA || hideAddToCart
          ? undefined
          : detachable && attached
            ? handleUpdateCart(product, 0, addToCart, onAttach)
            : handleAddToCart(
                  {
                      ...product,
                      listPrice: priceObj && (priceObj.salePrice || priceObj.regularPrice),
                      ...derivedProductMetrics,
                      metricParams: analyticsData,
                      eligibleSKU,
                      removeExistingAssociation,
                  },
                  addToCart,
                  priceObj,
                  onAttach,
                  undefined,
                  {
                      ...(analyticsData || {}),
                      baseProductSku: sku, // used only for a2c on xsell popup
                  },
                  trackCustomMetric,
              );

    const productUrl = product.productUrl || link.url;
    const { xsellProdInfo, xsellMethod, list, position, cartId, gtmIdPrefix } = analyticsData || {};
    const gtmConfig = {
        list: list || 'pdp-accessories',
        metricKey: 'ctaClick',
        xsellProdInfo,
        xsellMethod,
        position,
        gtmIdPrefix,
    };
    const gtmPrice = { [sku]: priceObj };

    const { gtmActions: gtmProductClick } = addGtmProductClick(product, gtmConfig, gtmPrice);

    let gtmAddToCart = !isCto && (!detachable || !attached) && addGtmAddToCart(product, gtmConfig, gtmPrice, cartId);
    if (!isCto && detachable && attached) {
        gtmAddToCart = addGtmAddToCart(product, { ...(gtmConfig || {}), removeFromCart: true }, gtmPrice, cartId);
    }

    const gtmActions = new Map([
        ['productViewDetails', gtmProductClick.get('productClick')],
        [
            'productCtaAction',
            isCto ? gtmProductClick.get('productClick') : gtmAddToCart && gtmAddToCart.get('ctaClick'),
        ],
    ]);
    const { purchaseBtnProps } = getPurchaseButtonProps(
        product,
        priceObj,
        hooks,
        //handle analytics at the root level of props rather than the purchase button
        null,
        trackCustomMetric,
        { attached: detachable && attached },
    );
    const ViolatorProps = subscriptionTerm ? { value: `${subscriptionTerm} Subscription` } : undefined;
    const ButtonProps = hideAddToCart
        ? {}
        : {
              ...purchaseBtnProps,
              children: detachable && attached ? 'Remove Item' : purchaseBtnProps.children,
              color: detachable && attached ? 'white' : 'dark',
          };
    const productTileProps = {
        title: product.name,
        specs: product.keyPoints,
        translations: productTranslations,
        PriceBlockProps: price,
        ButtonProps,
        LinkProps: {
            children: ctaViewDetails,
            to: productUrl,
            target: '_blank',
            icon: 'external',
        },
        ViolatorProps,
        gtmActions,
    };
    if (!hideAddToCart && productTileProps.ButtonProps) {
        //override onClick with attachHandler
        productTileProps.ButtonProps.onClick = attachHandler;
    }

    return productTileProps;
};

export default getAttachableProductProps;
