import { addGtmPropsToProductTile } from '../../metrics/metrics-helpers';
import {
    getImageLink,
    mapBundleProducts,
    getRewardsBadge,
    getCompare,
    resolveOfferMsg,
    resolveViolatorMessage,
    getHeaderProps,
    getProductLinkProps,
    getTitle,
    getRatings,
    getImageUrl,
    getOffersModals,
} from './util';
import {
    getStockIndicator,
    getPunchOutProps,
    handleAddToCart,
    getEOLCTALink,
    shouldUseAddToQuote,
    getPriceProps,
    getPurchaseButtonProps,
    getButtonAction,
} from './cart';
export * from './util';
export * from './cart';
export { default as getStoreAppProductProps, getSaleCalloutText } from './store-app-product.js';
export { getProductShowcaseProps, getProductShowcasePropsOld } from './product-showcase-props';
export { default as getAttachableProductProps, getCrossSellProductProps } from './product-attachable-props';

/**
 * Derives commonly use props from product and price information
 * @param {*} product
 * @param {*} price
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */
function getProductProps(product, priceObj, hooks, options = { resizeImage: false }, analyticsData, trackCustomMetric) {
    const { name, title } = product;
    const { compare = {}, colorSelection } = hooks || {};
    const { titleTag } = options;

    const { isOOS, facet_subbrand, plcode, pStoreID } = priceObj || {};
    const priceProps = getPriceProps(product, priceObj, options);
    const ratings = getRatings(product, options);
    const rewardBadge = getRewardsBadge(product, pStoreID);
    //peak the first image, if the format is not correct map it
    const [firstImage] = product.images || [];
    const images =
        !options.resizeImage && (!firstImage || firstImage.src)
            ? product.images
            : (product.images || []).map((image, idx) => {
                  return {
                      alt: image.alt || image.altTex || image.altText || name || title,
                      src: getImageLink(getImageUrl(image), options.resizeImage),
                      loading: idx === 0 ? 'eager' : 'lazy',
                  };
              });

    const translations = {
        buttons: {
            compareBtnText: 'ADD TO COMPARE',
        },
    };
    const transformedProduct = {
        ...product,
        // TODO: need for compare cta to work, its using purchaseButton component
        prdClass: product.product_class,
        name: product.title || product.name,
        title: getTitle(product, titleTag),
        // api inconsistency CTO badge comes in as productBadge in VWA and productType in MDP
        productType: product.productBadge || product.productType,
        violatorMessage: resolveViolatorMessage(product.mktdiff),
        offerMessage: resolveOfferMsg(product.violatorMessage || product.violatorMsg, priceObj),
        specFirstGlance: product.specFirstGlance || product.keyPoints,
        images,
        isOOS,
        facet_subbrand,
        plcode,
    };
    const productTileProps = {
        ...getHeaderProps(product, priceObj),
        product: transformedProduct,
        ...getPunchOutProps(priceObj),
        ratings,
        priceProps,
        translations,
        rewardBadge,
        bundleModule: mapBundleProducts(product.bundleComponents, priceObj),
        //Exclude titleTag from title, it is not supported by the compare bar
        ...getCompare({ ...transformedProduct, title: getTitle(product) }, compare),
        ...getPurchaseButtonProps(product, priceObj, hooks, analyticsData, trackCustomMetric),
        ...getProductLinkProps(product, priceObj, analyticsData, null),
        ...getOffersModals(product, compare, priceObj),
        colorSelection,
    };

    return productTileProps;
}

export default getProductProps;
