import { resolveOfferMsg, cleanText, getTileCtaLink, getProductLinkProps, getTitle, getImageLink } from './util';
import { getPunchOutProps, getPriceProps } from './cart';
import { getHeaderProps } from '../get-product-props/util';

/**
 * Returns props for the Showcase variation of the stellar ProductTile
 * @param {*} product
 * @param {*} price
 * @returns
 */
export const getProductShowcaseProps = (product, price, options = {}) => {
    const { specFirstGlance, title, name, sku, ctaViewDetailsLink, ctaViewDetails, productUrl, images, imageUrl } =
        product;
    const { analyticsData, titleTag, closeButton, seeMoreLink, priceProps, headerProps: headerPropsOptions } = options;
    const ctaLink = getTileCtaLink(ctaViewDetailsLink || productUrl);

    let { headerProps } = getHeaderProps(product, price, { hideStockIndicator: true, ...(headerPropsOptions || {}) });

    return {
        variant: 'showcase',
        headerProps: {
            ...headerProps,
            closeBtnProps: closeButton && {
                'aria-label': 'Remove product',
                onClick: closeButton,
            },
        },
        priceProps: getPriceProps(product, price, priceProps),
        product: {
            images: imageUrl ? [{ src: getImageLink(imageUrl, true) }] : images,
            title: getTitle(product, titleTag),
            productUrl: ctaLink,
            specFirstGlance,
            sku,
        },
        seeMoreLinkProps: seeMoreLink && {
            children: 'See more',
            to: seeMoreLink,
        },
        ...getProductLinkProps(product, price, analyticsData, { children: ctaViewDetails, color: 'dark' }),
        ...getPunchOutProps(price),
    };
};

/**
 * Returns props for a "showcase" ProductTile for legacy use (CompareTabl stellar component still uses this)
 * @param {*} product
 * @param {*} price
 * @returns
 */
export const getProductShowcasePropsOld = (product, price) => {
    const {
        specFirstGlance,
        title,
        name,
        isOOS,
        sku,
        ctaViewDetailsLink,
        ctaViewDetails,
        offerMessage,
        violatorMessage,
        violatorMsg,
        productUrl,
        images,
        imageUrl,
        usageLabel,
        product_type,
    } = product;

    return {
        product: {
            title: cleanText(name || title),
            productUrl: getTileCtaLink(ctaViewDetailsLink || productUrl),
            offerMessage: resolveOfferMsg(offerMessage || violatorMessage || violatorMsg, price),
            specFirstGlance,
            sku,
            usageLabel,
        },
        price,
        //check for imageUrl for legacy pages like blog product ES search results
        showcaseImage: imageUrl ? { src: imageUrl } : images?.[0],
        translations: {
            buttons: {
                productLinkBtnText: ctaViewDetails,
                purchaseBtnText: isOOS ? 'Out of Stock' : product_type === 'CTO' ? 'Customize & Buy' : 'Add to cart',
                compareBtnText: 'Add to compare',
            },
            price: {
                disclaimer: null,
            },
        },
        ...getPunchOutProps(price),
        stockIndicator: {
            stockMessage: isOOS ? 'Out of Stock' : 'In stock',
            color: isOOS ? 'red' : 'green',
        },
    };
};
